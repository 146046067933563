import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './TagPage.scss';

const TagPage = ({ headLine, path }) => (
  <Link className='tag-page' to={path}>{headLine}</Link>
);

export default TagPage;

TagPage.propTypes = {
  headLine: PropTypes.string,
  path: PropTypes.string,
};
