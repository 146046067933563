import React from 'react';
import PropTypes from 'prop-types';

const RubricsTags = require('../../../constants/Rubrics');

import TagPage from '../TagPage/TagPage';

import './Rubrics.scss';

const Rubrics = ({ lang }) => (
  <div className='rubrics'>
    <p className='rubrics-title'>Рубрики: </p>
    {Object.keys(RubricsTags).map(key => (
      <TagPage
        key={key}
        path={`${lang === 'ru' ? '' : '/uk'}/novosti/tag/${key}`}
        headLine={RubricsTags[key][lang]}
      />
    ))}
  </div>
);

export default Rubrics;

Rubrics.propTypes = {
  lang: PropTypes.string,
};
